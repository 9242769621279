import React, { lazy } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import UsersRoute from "./helpers/UsersRoute";
import { useAdmin } from "./context/AdminContext";
import AdminRoute from "./helpers/AdminRoute";
import "./index.css";

const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const EmployeeProfile = lazy(() => import("./pages/Profiles/EmployeeProfile"));
const UserProfile = lazy(() => import("./pages/Profiles/UserProfile"));
const EmployeeAttendance = lazy(() => import("./pages/EmployeeAttendance"));

function App() {
  const { userProfile } = useAdmin();
  return (
    <>
      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>
          <Redirect exact from="/" to="/login" />
          <Route path="/login" component={Login} />
          <UsersRoute
            path="/user/:id"
            component={UserProfile}
            user={userProfile}
          />
          <UsersRoute
            path="/employee/:id"
            component={EmployeeProfile}
            user={userProfile}
          />
          <AdminRoute path="/app/*" component={Layout} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
