import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAdmin } from "../context/AdminContext";

const UsersRoute = ({ component: Component, user, ...rest }) => {
  const { currentUser } = useAdmin();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (currentUser) {
          const displayName = currentUser.providerData[0].displayName;
          if (displayName === "employee" || displayName === "user") {
            return <Component {...props} />;
          }
        }
        return <Redirect to="/login" />;
      }}
    />
  );
};

export default UsersRoute;
