import React, { createContext, useContext, useState, useCallback } from "react";
import { auth, db } from "../firebase"; // Adjust the import path as necessary
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  updateProfile,
} from "firebase/auth";
import {
  doc,
  setDoc,
  getDoc,
  collection,
  addDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  query,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";
import { toastError, toastSuccess } from "../helpers/toast";
import { sendMail } from "../helpers/email";

const AdminContext = createContext();

export const useAdmin = () => useContext(AdminContext);

export const AdminProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const createUser = async (dbName, data, setLoading, role) => {
    setLoading(true);
    try {
      const { password, ...dataWithoutPassword } = data;
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        data?.email,
        password
      );

      await updateProfile(userCredential.user, {
        displayName: role,
      });

      const ref = doc(db, dbName, userCredential.user.uid);
      await setDoc(ref, dataWithoutPassword)
        .then(() => {
          toastSuccess(`${dbName} Created Successfully`);

          setLoading(false);
          sendMail(data.email, "registered", password);
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
          setLoading(false);
        });
    } catch (error) {
      toastError(error.message);
      setError(error.message);
      setLoading(false);
    }
  };

  const signIn = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      setIsAuthenticated(true);

      if (userCredential.user.displayName != "admin") {
        try {
          const userData = await getCurrentUserData(userCredential.user.uid);
          setUserProfile({ ...userData, id: userCredential.user.uid });
        } catch (error) {
          console.error("Failed to get user data:", error);
        }
      }

      setCurrentUser(userCredential.user);
      sessionStorage.setItem("user", JSON.stringify(userCredential.user));
      return userCredential.user;
    } catch (error) {
      setError(error.message);
      return null;
    }
  };

  const signOut = async () => {
    try {
      await auth.signOut();
      setIsAuthenticated(false);
      toastSuccess("Successfully signed out");
      sessionStorage.removeItem("user");
      setCurrentUser(null);
    } catch (error) {
      toastError(error.message);
      setError(error.message);
    }
  };

  const getDocument = useCallback(async (collection, uid) => {
    try {
      if (!uid) {
        return null;
      }
      const dbRef = doc(db, collection, uid);
      const docSnap = await getDoc(dbRef);
      if (docSnap.exists()) {
        return docSnap.data();
      }
    } catch (error) {
      toastError(error.message);
      return null;
    }

    return null;
  }, []);

  const getCurrentUserData = useCallback(
    async (uid, role) => {
      try {
        // Check the "Employee" collection
        let userData = await getDocument("Employee", uid);

        if (!userData) {
          // Check the "Users" collection
          userData = await getDocument("Users", uid);
        }

        if (userData) {
          setUserProfile(userData);
        } else {
          if (!role === null) toastError("No such document!");
        }
        return userData;
      } catch (error) {
        toastError(error.message);
        return null;
      }
    },
    [getDocument]
  );

  const storeData = async (dbName, data, setloading) => {
    setloading(true);
    try {
      const docRef = await addDoc(collection(db, dbName), data)
        .then(() => {
          toastSuccess(`${dbName} Created Successfully`);
          setloading(false);
        })
        .catch((error) => {
          toastError(error.message);
          setloading(false);
          console.error(`Error adding ${dbName} : `, error);
        });
    } catch (error) {
      toastError(error.message);
      setError(error.message);
      setloading(false);
    }
  };

  const getFacilityData = async (setFacilityData, setloading) => {
    try {
      setloading(true);
      const facilityRef = collection(db, "Facility");
      const snapshot = await getDocs(facilityRef);

      const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setFacilityData(data);
      setloading(false);
    } catch (error) {
      toastError(error.message);
      setError(error.message);
      toastError("Error getting documents: ", error.message);
      setloading(false);
    }
  };

  const getData = useCallback(async (dbName, setloading, setData) => {
    setloading(true);
    try {
      const data = [];
      const querySnapshot = await getDocs(collection(db, dbName));
      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
      });
      setData(data);
      setloading(false);
    } catch (error) {
      toastError(error.message);
      setError(error.message);
      setloading(false);
    }
  }, []);

  const getServerData = async (
    dbName,
    setloading,
    setData,
    page,
    pageSize,
    lastVisible
  ) => {
    setloading(true);
    try {
      const data = [];
      let q = query(collection(db, dbName), orderBy("id"), limit(pageSize));

      if (page !== 1) {
        q = query(
          collection(db, dbName),
          orderBy("id"),
          startAfter(lastVisible),
          limit(pageSize)
        );
      }

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
      });

      // Get the last visible document
      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

      setloading(false);
    } catch (error) {
      toastError(error.message);
      setError(error.message);
      setloading(false);
    }
  };

  const updateData = async (docId, dbName, updatedData, setloading) => {
    try {
      setloading(true);
      const docRef = doc(db, dbName, docId);
      const { id, ...dataWithoutId } = updatedData;
      await updateDoc(docRef, dataWithoutId);
      toastSuccess(`${dbName} Updated Successfully`);
      setloading(false);
    } catch (error) {
      toastError(error.message);
      setError(error.message);
      setloading(false);
    }
  };

  const deleteData = async (docId, dbName, setloading) => {
    try {
      setloading(true);
      const docRef = doc(db, dbName, docId);
      await deleteDoc(docRef);
      toastSuccess("Document deleted successfully");
      setloading(false);
    } catch (error) {
      toastError(error.message);
      setError(error.message);
      setloading(false);
    }
  };

  const recordAttendance = async (empId, role, action) => {
    const attendanceRef = doc(db, "EmpAttendance", empId);

    // Get the current date in 'YYYY-MM-DD' format
    const currentDate = new Date().toISOString().split("T")[0];

    if (role === "Employee") {
      // Get the current attendance document
      const docSnap = await getDoc(attendanceRef);

      if (action === "start") {
        // If the start time for the current date already exists, return from the function
        if (
          docSnap.exists() &&
          docSnap.data()[currentDate] &&
          docSnap.data()[currentDate].startTime
        ) {
          toastError("You have already started your attendance for today.");
          return;
        }

        // Record the start time
        await setDoc(
          attendanceRef,
          {
            [currentDate]: {
              startTime: serverTimestamp(),
            },
          },
          { merge: true }
        );

        toastSuccess("Attendance started successfully.");
      } else if (action === "end") {
        // If the end time for the current date already exists, return from the function
        if (
          docSnap.exists() &&
          docSnap.data()[currentDate] &&
          docSnap.data()[currentDate].endTime
        ) {
          toastError("You have already ended your attendance for today.");
          return;
        }

        // Record the end time
        await updateDoc(attendanceRef, {
          [`${currentDate}.endTime`]: serverTimestamp(),
        });
        toastSuccess("Attendance ended successfully.");
      }
    }
  };

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in

        const uid = user.uid;
        const userData = await getCurrentUserData(uid, user.displayName);
        setUserProfile(userData);
        setCurrentUser(user);
        setLoading(false);

        // Store user in sessionStorage
      } else {
        setUserProfile(null);
        setCurrentUser(null);
        setLoading(false);
      }
    });
    return () => unsubscribe();
  }, []);

  React.useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    if (user) {
      setCurrentUser(user);
    }
  }, []);

  const VerifyAccount = async (docId, dbName, setloading) => {
    try {
      setloading(true);
      const docRef = doc(db, dbName, docId);
      const docSnap = await getDoc(docRef);
      const isVerified = docSnap.data()?.isVerified;
      if (isVerified) {
        toastError("Account is already verified");
      } else {
        await updateDoc(docRef, { isVerified: true });
        toastSuccess("Account Verified Successfully");
      }
      setloading(false);
    } catch (error) {
      toastError(error.message);
      setError(error.message);
      setloading(false);
    }
  };

  const value = {
    currentUser,
    error,
    createUser,
    signIn,
    signOut,
    loading,
    isAuthenticated,
    userProfile,
    storeData,
    getData,
    getFacilityData,
    updateData,
    deleteData,
    recordAttendance,
    getDocument,
    getServerData,
    VerifyAccount,
  };
  return (
    <AdminContext.Provider value={value}>
      {!loading && children}
    </AdminContext.Provider>
  );
};
