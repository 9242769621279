import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./assets/css/tailwind.output.css";
import App from "./App";
import { SidebarProvider } from "./context/SidebarContext";
import ThemedSuspense from "./components/ThemedSuspense";
import { Windmill } from "@windmill/react-ui";
import * as serviceWorker from "./serviceWorker";
import { AdminProvider } from "./context/AdminContext";
import "react-toastify/dist/ReactToastify.css";
import ToastProvider from "./context/ToastProvider";

ReactDOM.render(
  <AdminProvider>
    <ToastProvider>
      <SidebarProvider>
        <Suspense fallback={<ThemedSuspense />}>
          <Windmill usePreferences>
            <App />
          </Windmill>
        </Suspense>
      </SidebarProvider>
    </ToastProvider>
  </AdminProvider>,

  document.getElementById("root")
);

serviceWorker.register();
