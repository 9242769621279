import axios from "axios";
import { toastError, toastSuccess } from "./toast";

const API_URL = "https://dashboardapi-two.vercel.app/send-email";

export const sendMail = async (email, action, password = "") => {
  try {
    const response = await axios.post(API_URL, {
      to: email,
      subject: "Greetings from the BioWasteAgency!!",
      text: "Greetings from the BioWasteAgency!!",
      html: getEmailBody(action, email, password),
    });
    console.log(response);
    toastSuccess("Mail sent successfully");
  } catch (error) {
    toastError(error.message);
  }
};

const getEmailBody = (action, email, password) => {
  const baseStyle = `
    body { font-family: poppins, sans-serif; line-height: 1.6; color: #333; max-width: 600px; margin: 0 auto; padding: 20px; }
    h1 { color: #2c3e50; border-bottom: 2px solid #3498db; padding-bottom: 10px; }
    p { margin-bottom: 15px; }
    .button { display: inline-block; padding: 10px 20px; background-color: #3498db; color: #ffffff; text-decoration: none; border-radius: 5px; }
    .footer { margin-top: 20px; font-size: 12px; color: #7f8c8d; border-top: 1px solid #bdc3c7; padding-top: 10px; }
  `;

  if (action === "verified") {
    return `
      <html>
        <head>
          <style>${baseStyle}</style>
        </head>
        <body>
          <h1>Verification Successful</h1>
          <p>Dear Valued Client,</p>
          <p>We are pleased to inform you that your documents have been successfully verified. Your cooperation in this process is greatly appreciated.</p>
          <p>You can now proceed with using our services. If you have any questions or need further assistance, please don't hesitate to contact our support team.</p>
          <p>Thank you for choosing BioWasteAgency.</p>
          <div class="footer">
            <p>This is an automated message. Please do not reply to this email.</p>
          </div>
        </body>
      </html>
    `;
  } else if (action === "rejected") {
    return `
      <html>
        <head>
          <style>${baseStyle}</style>
        </head>
        <body>
          <h1>Verification Not Successful</h1>
          <p>Dear Valued Client,</p>
          <p>This message confirms that your application form has been rejected and your documentation process has been halted.</p>
          
          <div class="footer">
            <p>This is an automated message. For support, please contact our help desk.</p>
          </div>
        </body>
      </html>
    `;
  } else if (action === "underprocessing") {
    return `
      <html>
        <head>
          <style>${baseStyle}</style>
        </head>
        <body>
          <h1>Verification in Progress</h1>
          <p>Dear Valued Client,</p>
          <p>We want to inform you that your documents are currently under review as part of our verification process. Our team is working diligently to complete this as quickly as possible.</p>
          <p>The typical verification process takes 2-3 business days. We appreciate your patience during this time.</p>
          <p>If you have any questions or need to provide additional information, please don't hesitate to contact our support team.</p>
          <p>Thank you for your cooperation and for choosing BioWasteAgency.</p>
          <div class="footer">
            <p>This is an automated message. For urgent matters, please contact our support team directly.</p>
          </div>
        </body>
      </html>
    `;
  } else if (action === "registered") {
    return `
      <html>
        <head>
          <style>${baseStyle}</style>
        </head>
        <body>
          <h1>Welcome to BioWasteAgency Portal</h1>
          <p>Dear New Member,</p>
          <p>Welcome aboard! Your profile has been successfully created in the BioWasteAgency Portal. We're excited to have you join our community of responsible waste management professionals.</p>
          <p>To ensure the security of your account, please verify your email address by clicking the button below:</p>
          <p>
            <a href="https://dashboard.biowasteagency.com/login" class="button">Verify Email</a>
          </p>
          <p>For your records, here are your login credentials:</p>
          <ul>
            <li><strong>Email:</strong> ${email}</li>
            <li><strong>Password:</strong> ${password}</li>
          </ul>
          <p>We strongly recommend changing your password after your first login for security purposes.</p>
          <p>If you have any questions or need assistance, our support team is here to help.</p>
          <p>Thank you for joining BioWasteAgency. Together, we can make a positive impact on our environment!</p>
          <div class="footer">
            <p>This is an automated message. Please do not reply to this email. For support, visit our help center.</p>
          </div>
        </body>
      </html>
    `;
  } else if (action === "qrcodeprocessing") {
    return `
      <html>
        <head>
          <style>${baseStyle}</style>
        </head>
        <body>
          <h1>QR Code Generation in Progress</h1>
          <p>Dear Valued Client,</p>
          <p>We want to inform you that your request for a QR code is currently being processed. Our system is working on generating a unique QR code for your specific needs.</p>
          <p>The QR code generation process typically takes a few minutes to complete. We will notify you as soon as your QR code is ready for use.</p>
          <p>If you have any questions or concerns while waiting, please don't hesitate to reach out to our support team.</p>
          <p>Thank you for your patience and for using BioWasteAgency's services.</p>
          <div class="footer">
            <p>This is an automated message. For urgent inquiries, please contact our support team directly.</p>
          </div>
        </body>
      </html>
    `;
  } else if (action === "qrcodecompleted") {
    return `
      <html>
        <head>
          <style>${baseStyle}</style>
        </head>
        <body>
          <h1>Your QR Code is Ready</h1>
          <p>Dear Valued Client,</p>
          <p>Great news! Your application form has  been proceeded over for your documentation process via the registered facility</p>
          
          <p>If you need any assistance with implementing your QR code or have any questions, our support team is ready to help.</p>
          <p>Thank you for using BioWasteAgency's QR code service. We're committed to making your waste management processes more efficient and environmentally friendly.</p>
          <div class="footer">
            <p>This is an automated message. For support or inquiries, please contact our help desk.</p>
          </div>
        </body>
      </html>
    `;
  }
};
