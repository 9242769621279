import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAdmin } from "../context/AdminContext";

const AdminRoute = ({ component: Component, user, ...rest }) => {
  // const { currentUser } = useAdmin();
  const currentUser = JSON.parse(sessionStorage.getItem("user"));
  return (
    <Route
      {...rest}
      render={(props) => {
        if (currentUser) {
          const displayName = currentUser.providerData[0].displayName;
          if (displayName === null) {
            return <Component {...props} />;
          }
        }
        return <Redirect to="/login" />;
      }}
    />
  );
};

export default AdminRoute;
